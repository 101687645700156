import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import OnboardingChecklistLandingPage from '@src/pages/OnboardingChecklist/OnboardingChecklistLandingPage'
import { Box, Layout } from '@revolut/ui-kit'
import Requisitions from '@src/pages/OnboardingChecklist/Requisitions'
import Teams from '@src/pages/OnboardingChecklist/Teams'
import Roles from '@src/pages/OnboardingChecklist/Roles'
import Locations from '@src/pages/OnboardingChecklist/Locations'
import Initial from '@src/pages/OnboardingChecklist/Initial'
import Employees from '@src/pages/OnboardingChecklist/Employees'
import JobPostings from '@src/pages/OnboardingChecklist/JobPostings'
import { Contracts } from './Contracts'
import Candidates from '@src/pages/OnboardingChecklist/Candidates'
import HiringProcess from '@src/pages/OnboardingChecklist/HiringProcess'
import CareersPage from '@src/pages/OnboardingChecklist/CareersPage'
import Performance from '@src/pages/OnboardingChecklist/Performance'
import { Lifecycle } from '@src/pages/OnboardingChecklist/Lifecycle'
import { Payroll } from '@src/pages/OnboardingChecklist/Payroll'
import { KPI } from './KPI'
import { SkillsAndValues } from './SkillsAndValues'
import { Documents } from '@src/pages/OnboardingChecklist/Documents'
import { Probation } from '@src/pages/OnboardingChecklist/Probation'
import { TimeOff } from '@src/pages/OnboardingChecklist/TimeOff'
import { Roadmaps } from './Roadmaps'
import { Finish } from '@src/pages/OnboardingChecklist/Finish'
import { WelcomePage } from '@src/pages/OnboardingChecklist/WelcomePage'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'

const OnboardingChecklist = () => {
  return (
    <Layout variant="container(wide) main(wide) side(wide)">
      <Layout.Menu />
      <Layout.Main>
        <Switch>
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.ALL}
            component={OnboardingChecklistLandingPage}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.INITIAL.ANY}
            component={Initial}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.EMPLOYEES.ANY}
            component={Employees}
          />
          <Route exact path={ROUTES.ONBOARDING_CHECKLIST.TEAMS.ANY} component={Teams} />
          <Route exact path={ROUTES.ONBOARDING_CHECKLIST.ROLES.ANY} component={Roles} />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.ANY}
            component={Locations}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.ANY}
            component={Requisitions}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.ANY}
            component={JobPostings}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.ANY}
            component={Candidates}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.ANY}
            component={HiringProcess}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.ANY}
            component={CareersPage}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.ANY}
            component={TimeOff}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.ANY}
            component={Documents}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.ANY}
            component={Contracts}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.ANY}
            component={Lifecycle}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.ANY}
            component={Performance}
          />
          <Route exact path={ROUTES.ONBOARDING_CHECKLIST.KPI.ANY} component={KPI} />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.PAYROLL.ANY}
            component={Payroll}
          />
          <Route
            exact
            path={[
              ROUTES.ONBOARDING_CHECKLIST.SKILLS.ANY,
              ROUTES.ONBOARDING_CHECKLIST.VALUES.ANY,
            ]}
            component={SkillsAndValues}
          />
          <Route
            exact
            path={[ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.ANY]}
            component={Roadmaps}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.PROBATION.ANY}
            component={Probation}
          />
          <Route exact path={ROUTES.ONBOARDING_CHECKLIST.FINISH.ANY} component={Finish} />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST.WELCOME}
            component={WelcomePage}
          />
          <Route>
            <PageNotFoundWidget />
          </Route>
        </Switch>
        <Box id="approval-sidebar-portal" />
      </Layout.Main>
      <Layout.Side />
    </Layout>
  )
}

export default OnboardingChecklist
