import React from 'react'
import { Token, Box, Flex } from '@revolut/ui-kit'

export const PageWidgetWrapper: React.FC = ({ children }) => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Box width="100%" maxWidth={Token.breakpoint.md}>
        {children}
      </Box>
    </Flex>
  )
}
