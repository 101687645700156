import React from 'react'
import { ErrorWidget } from '@revolut/ui-kit'
import { captureError } from '@src/features/Errors/captureError'

interface ErrorBoundaryProps {
  fallback?: React.ReactNode
  retryAction?: () => void
}

export const DefaultFallBack = (props: { retryAction?: () => void }) => {
  return (
    <ErrorWidget>
      <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D018.png" />
      <ErrorWidget.Title />
      <ErrorWidget.Description>
        The team has been notified. Please try again later.
      </ErrorWidget.Description>
      <ErrorWidget.Action
        onClick={() => {
          if (props?.retryAction) {
            props.retryAction()
          } else {
            window.location?.reload?.()
          }
        }}
      />
    </ErrorWidget>
  )
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    captureError(error, { tags: { component: 'ErrorBoundry' } })
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || <DefaultFallBack retryAction={this.props.retryAction} />
      )
    }

    return this.props.children
  }
}
