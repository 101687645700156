import { useDispatch } from 'react-redux'
import { PUBLIC_PREFIX, ROUTES, SIGNUP, WORKSPACES } from '@src/constants/routes'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useWorkspaceContext } from './WorkspaceContext'
import { logOutAction } from '@src/store/auth/actions'
import { logoutTenant } from '@src/api'
import { workspaceLocalStorage } from './workspaceLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'

const notAllowedWorkspaces = [
  WORKSPACES.MAIN,
  WORKSPACES.FIND,
  ROUTES.MAIN,
  PUBLIC_PREFIX,
  SIGNUP,
  ROUTES.LOGIN.MAIN,
  ROUTES.GRANT_PERMISSIONS_REDIRECT,
  ROUTES.CONTACT_US,
]
export const getWorkspaceFromPathname = () => {
  const pathname = window.location.pathname
  const secondSubrouteIndex = pathname.indexOf('/', 1)
  const firstSubroute =
    pathname.substring(0, secondSubrouteIndex > 0 ? secondSubrouteIndex : undefined) ||
    '/'
  return notAllowedWorkspaces.includes(firstSubroute)
    ? undefined
    : firstSubroute.replace('/', '').toLowerCase()
}

export const useRemoveWorkspacePopup = () => {
  const confirmationDialog = useConfirmationDialog()
  const workspaceContext = useWorkspaceContext()

  const dispatch = useDispatch()

  const showPopup = (workspace: string) =>
    confirmationDialog.show({
      body: `Are you sure you want to remove ${
        workspaceContext?.workspaces.find(w => w.name === workspace)?.companyName ||
        workspace
      } workspace?`,
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: () => {
        workspaceLocalStorage.removeItem(LocalStorageKeys.AUTHENTICATED)
        workspaceContext?.removeWorkspace(workspace)
        confirmationDialog.hide()

        if (workspace === workspaceContext?.workspace) {
          dispatch(logOutAction())
        } else {
          logoutTenant(workspace)
        }
      },
    })

  return {
    remove: (workspace: string) => showPopup(workspace),
  }
}
