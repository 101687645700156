import React from 'react'
import {
  Box,
  Group,
  ProgressStep,
  ProgressWidget,
  StatusWidget,
  Subheader,
  chain,
  VStack,
} from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { useSubscriptionPlanDetails } from '@src/api/plans'
import { PageError } from '@src/features/Errors/components/Page/PageError'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { formatMoney } from '@src/utils/format'
import { SUBSCRIPTION_CURRENCY, formatSubscriptionFreePeriod } from './common'

export const PlanDetails = () => {
  const params = useParams<{ id: string }>()

  const { error, isFetching, data: planDetails } = useSubscriptionPlanDetails(params.id)

  if (error) {
    return <PageError error={error} />
  }

  if (!planDetails || isFetching) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        title={planDetails.name}
        backUrl={ROUTES.SETTINGS.PLAN_MANAGEMENT.LIST}
      />

      <PageBody>
        <FormPreview data={planDetails} title="Plan Details" titleVariant="default">
          <Group>
            <FormPreview.Item
              title="Price per user"
              insert={() =>
                formatMoney(Number(planDetails.monthly_fee), planDetails.currency)
              }
            />
            <FormPreview.Item
              title="Trial period length"
              insert={() => formatSubscriptionFreePeriod(planDetails.free_days)}
            />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <Subheader>
            <Subheader.Title>Default Discounts</Subheader.Title>
          </Subheader>

          {planDetails.discounts.length > 0 ? (
            <VStack>
              <ProgressWidget style={{ padding: 0 }}>
                <ProgressWidget.Steps variant="vertical-compact">
                  {planDetails.discounts.map(discount => (
                    <ProgressStep key={discount.id}>
                      <ProgressStep.Description>
                        {chain(
                          `${formatMoney(
                            Number(discount.monthly_fee),
                            SUBSCRIPTION_CURRENCY,
                          )} per active user per month`,
                          formatSubscriptionFreePeriod(discount.days),
                        )}
                      </ProgressStep.Description>
                    </ProgressStep>
                  ))}
                </ProgressWidget.Steps>
              </ProgressWidget>
            </VStack>
          ) : (
            <StatusWidget>
              <StatusWidget.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images/3D022.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images/3D022@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images/3D022@3x.png',
                }}
              />
              <StatusWidget.Title>
                {planDetails.name} Plan Discounts will appear here
              </StatusWidget.Title>
            </StatusWidget>
          )}
        </Box>
      </PageBody>
    </PageWrapper>
  )
}
