import React, { useState } from 'react'
import {
  VStack,
  Text,
  Token,
  MoreBar,
  BottomSheet,
  Header,
  Button,
  useStatusPopup,
  StatusPopup,
  Input,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { formatMoney, formatPeriod } from '@src/utils/format'
import { PageError } from '@src/features/Errors/components/Page/PageError'
import { saveFile } from '@src/utils'
import { UseFetchResult } from '@src/interfaces'
import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { goBack } from '@src/actions/RouterActions'
import { InvoiceEmployeesPreview, InvoiceSummary, InvoiceTotal } from './common'
import { cancelInvoice, markInvoiceAsPaid } from '@src/api/plans'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

interface InvoiceDetailsPageProps {
  data: UseFetchResult<SubscriptionInvoiceInterface>
  downloadInvoiceCallback: () => Promise<{ file: string; fileName: string }>
  backUrl: string
  deleteInvoice?: () => Promise<any>
  showMarkAsPaidButton: boolean
  showAdminCancelInvoiceButton: boolean
}

export const InvoiceDetailsPage = ({
  data,
  downloadInvoiceCallback,
  deleteInvoice,
  backUrl,
  showMarkAsPaidButton,
  showAdminCancelInvoiceButton,
}: InvoiceDetailsPageProps) => {
  const params = useParams<{ id: string; invoiceId: string }>()

  const statusPopup = useStatusPopup()

  const featureFlags = useSelector(selectFeatureFlags)

  const [invoiceDownloadPending, setInvoiceDownloadPending] = useState(false)
  const [invoiceDeletePending, setInvoiceDeletePending] = useState(false)
  const [invoiceCancelPending, setInvoiceCancelPending] = useState(false)
  const [deleteInvoiceConfirmationPopupOpen, setDeleteInvoiceConfirmationPopupOpen] =
    useState(false)
  const [cancelInvoiceConfirmationPopupOpen, setCancelInvoiceConfirmationPopupOpen] =
    useState(false)
  const [markInvoiceAsPaidPopupOpen, setMarkInvoiceAsPaidPopupOpen] = useState(false)
  const [markInvoiceAsPaidPending, setMarkInvoiceAsPaidPending] = useState(false)
  const [invoiceSettlementNumber, setInvoiceSettlementNumber] = useState('')

  const { data: invoice, isFetching, error, refetch } = data

  const subscriptionPlansDevtoolsEnabled = featureFlags.includes(
    FeatureFlags.SubscriptionPlansDevtools,
  )

  if (error) {
    return <PageError error={error} />
  }

  if (!invoice || isFetching) {
    return <PageLoading />
  }

  const onDeleteInvoice = () => {
    setInvoiceDeletePending(true)

    deleteInvoice?.()
      .then(() => {
        setDeleteInvoiceConfirmationPopupOpen(false)
        goBack()
      })
      .catch(err => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to delete invoice</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(err)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
        setInvoiceDeletePending(false)
      })
  }

  const onCancelInvoice = () => {
    setInvoiceCancelPending(true)

    cancelInvoice(params.id, params.invoiceId)
      .then(() => {
        refetch()
        setCancelInvoiceConfirmationPopupOpen(false)

        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Success</StatusPopup.Title>
            <StatusPopup.Description>Invoice cancelled</StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .catch(err => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to cancel invoice</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(err)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
        setInvoiceCancelPending(false)
      })
  }

  const onCloseMarkInvoiceAsPaidPopup = () => {
    setMarkInvoiceAsPaidPopupOpen(false)
    setInvoiceSettlementNumber('')
  }

  const onConfirmMarkInvoiceAsPaid = () => {
    setMarkInvoiceAsPaidPending(true)

    markInvoiceAsPaid(params.invoiceId, params.id, {
      settlement_reference: invoiceSettlementNumber,
    })
      .then(() => {
        refetch()
        onCloseMarkInvoiceAsPaidPopup()

        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Success</StatusPopup.Title>
            <StatusPopup.Description>Invoice record updated</StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .catch(err => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to mark invoice as paid</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(err)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
      .finally(() => setMarkInvoiceAsPaidPending(false))
  }

  return (
    <>
      <PageHeader
        title={`Invoice ${invoice.order_number || ''}`}
        subtitle={
          <VStack space="s-2">
            <Text variant="primary" color={Token.color.foreground}>
              {formatPeriod(invoice.start_date, invoice.end_date)}
            </Text>
            <Text>
              {formatMoney(Number(invoice.original_subscription_fee), invoice.currency)}{' '}
              per active employee/month.
            </Text>
          </VStack>
        }
        backUrl={backUrl}
      />

      <PageBody>
        <MoreBar>
          {invoice.manual_payment ? null : (
            <MoreBar.Action
              onClick={() => {
                setInvoiceDownloadPending(true)
                downloadInvoiceCallback()
                  .then(response => {
                    saveFile(response.file, response.fileName)
                  })
                  .finally(() => {
                    setInvoiceDownloadPending(false)
                  })
              }}
              useIcon="Download"
              pending={invoiceDownloadPending}
            >
              Download invoice
            </MoreBar.Action>
          )}
          {invoice.status.id === 'open' &&
          invoice.manual_payment &&
          showMarkAsPaidButton ? (
            <MoreBar.Action
              onClick={() => setMarkInvoiceAsPaidPopupOpen(true)}
              useIcon="Statement"
            >
              Mark as paid
            </MoreBar.Action>
          ) : null}
          {(subscriptionPlansDevtoolsEnabled ||
            (invoice?.manual_payment && invoice?.status.id === 'open')) &&
            !!deleteInvoice && (
              <MoreBar.Action
                onClick={() => setDeleteInvoiceConfirmationPopupOpen(true)}
                useIcon="Delete"
                variant="negative"
              >
                Delete
              </MoreBar.Action>
            )}

          {showAdminCancelInvoiceButton && invoice?.status.id === 'open' ? (
            <MoreBar.Action
              onClick={() => setCancelInvoiceConfirmationPopupOpen(true)}
              useIcon="Cross"
              variant="negative"
            >
              Cancel
            </MoreBar.Action>
          ) : null}
        </MoreBar>

        <VStack space="s-16" mt="s-24">
          <InvoiceSummary invoice={invoice} />
          <InvoiceEmployeesPreview invoice={invoice} />
          <InvoiceTotal invoice={invoice} />
        </VStack>
      </PageBody>

      <BottomSheet
        open={markInvoiceAsPaidPopupOpen}
        onClose={onCloseMarkInvoiceAsPaidPopup}
      >
        <Header>
          <Header.Title>Mark invoice as paid</Header.Title>
        </Header>

        <Input
          value={invoiceSettlementNumber}
          onChange={e => setInvoiceSettlementNumber(e.currentTarget.value)}
          label="Settlement reference number"
        />

        <BottomSheet.Actions horizontal>
          <Button onClick={onCloseMarkInvoiceAsPaidPopup} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={onConfirmMarkInvoiceAsPaid}
            pending={markInvoiceAsPaidPending}
            disabled={!invoiceSettlementNumber}
          >
            Save
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>

      <ConfirmationDialog
        open={deleteInvoiceConfirmationPopupOpen}
        onClose={() => setDeleteInvoiceConfirmationPopupOpen(false)}
        loading={invoiceDeletePending}
        onConfirm={() => onDeleteInvoice()}
        onReject={() => setDeleteInvoiceConfirmationPopupOpen(false)}
        label="Are you sure you want delete this invoice?"
        body=""
        yesMessage="Delete"
        noMessage="Cancel"
      />

      <ConfirmationDialog
        open={cancelInvoiceConfirmationPopupOpen}
        onClose={() => setCancelInvoiceConfirmationPopupOpen(false)}
        loading={invoiceCancelPending}
        onConfirm={() => onCancelInvoice()}
        onReject={() => setCancelInvoiceConfirmationPopupOpen(false)}
        label="Are you sure you want cancel this invoice?"
        body=""
        yesMessage="Yes"
        noMessage="No"
      />
    </>
  )
}
