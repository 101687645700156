import React, { useMemo } from 'react'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@components/TableV2/hooks'
import Stat from '@components/Stat/Stat'
import { EmployeeInterface } from '@src/interfaces/employees'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import {
  getGrowthPlansRelations,
  GrowthPlansRelationsQueryKeys,
} from '@src/api/growthPlans'
import { GrowthPlanRelationInterface } from '@src/interfaces/growthPlans'
import { getRow } from '@src/features/GrowthPlans/EmployeePlans/Row'
import { useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { growthPlanModel } from '@src/features/GrowthPlans/GrowthPlan/models/growthPlanModel'
import { PerformanceLayoutSubTabs } from '@src/pages/EmployeeProfile/Layout/Performance/LayoutTab'

interface Props {
  employee: EmployeeInterface
  navigation: React.ReactElement
}

const row = getRow()

export const EmployeePlansTable = ({ navigation }: Props) => {
  const { id } = useParams<{ id: string }>()

  const initialFilterBy = useMemo(
    () => ({
      filters: [{ name: `${id}`, id }],
      columnName: GrowthPlansRelationsQueryKeys.LineManagerId,
      nonResettable: true,
      nonInheritable: true,
    }),

    [id],
  )
  const table = useTable({ getItems: getGrowthPlansRelations }, [initialFilterBy])

  const onRowClick = ({
    id: relationId,
    current_growth_plan: currentGrowthPlan,
  }: GrowthPlanRelationInterface) => {
    if (!currentGrowthPlan) {
      navigateTo(pathToUrl(ROUTES.FORMS.GROWTH_PLAN.CREATE, { employeeId: relationId }))
    } else if (
      growthPlanModel.hasRequestedChanges(currentGrowthPlan.growth_plan) ||
      growthPlanModel.isDraft(currentGrowthPlan.growth_plan)
    ) {
      navigateTo(
        pathToUrl(ROUTES.FORMS.GROWTH_PLAN.EDIT, {
          employeeId: relationId,
          id: currentGrowthPlan.growth_plan.id,
        }),
      )
    } else {
      navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
          id: relationId,
          subtab: PerformanceLayoutSubTabs.Growth,
        }),
      )
    }
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Growth plans" val={table.loading ? undefined : table.count} />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <>
          <Table.Search
            placeholder="Search by employee name"
            onFilter={table.onFilterChange}
            variant="compact"
          />
          {navigation}
        </>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<GrowthPlanRelationInterface>
          name={TableNames.EmployeeGrowthPlans}
          row={row}
          hideCount
          {...table}
          emptyState={<EmptyTableRaw title="Growth plans will appear here." />}
          onRowClick={onRowClick}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
