import React from 'react'

import {
  DetailsCell,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Text,
  Token,
} from '@revolut/ui-kit'
import {
  ConnectionStatuses,
  ConnectionType,
  ExternalChannelItemInterface,
} from '@src/interfaces/deparment'

interface Props {
  channel: ExternalChannelItemInterface
  onChannelEdit: () => void
}
export const ExternalChannelItem = ({ channel, onChannelEdit }: Props) => {
  return (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack alignSelf="center">
          {channel.name}
          {channel.status === ConnectionStatuses.LinkError && (
            <Icon name="InfoOutline" color={Token.color.danger} ml="s-8" />
          )}
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Content>
        <HStack>
          {channel.href ? (
            <Link target="_blank" href={channel.href}>
              {channel.handle}
            </Link>
          ) : (
            <Text
              color={
                channel.status === ConnectionStatuses.Disabled
                  ? Token.color.greyTone50
                  : Token.color.foreground
              }
            >
              {channel.type === ConnectionType.Channel && '#'}
              {channel.type === ConnectionType.Group && '@'}
              {channel.handle}
            </Text>
          )}
          {channel.status === ConnectionStatuses.Disabled ? (
            <Spacer width="s-16" />
          ) : (
            <IconButton
              useIcon="ChevronRight"
              color={Token.color.greyTone50}
              onClick={onChannelEdit}
            />
          )}
        </HStack>
      </DetailsCell.Content>
    </DetailsCell>
  )
}
