import React from 'react'
import { ErrorWidget, Flex } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'

export type PageErrorProps = {
  title: string
  text?: string
  children?: React.ReactNode
}

/** @deprecated Use `@src/features/Errors/components/Page/PageError` instead */
const PageError = ({ title, text, children }: PageErrorProps) => {
  return (
    <PageWrapper justifyContent="center">
      <Flex justifyContent="center" alignItems="center">
        <ErrorWidget>
          <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
          <ErrorWidget.Title>{title}</ErrorWidget.Title>
          <ErrorWidget.Description>{text}</ErrorWidget.Description>
          {children}
        </ErrorWidget>
      </Flex>
    </PageWrapper>
  )
}

export default PageError
