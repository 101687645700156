import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Input, InputGroup, Subheader } from '@revolut/ui-kit'
import { connect } from 'lape'
import { addDays } from 'date-fns'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { useSubscriptionPlanDetails } from '@src/api/plans'
import { PageError } from '@src/features/Errors/components/Page/PageError'
import PageLoading from '@src/components/PageLoading/PageLoading'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import Form from '@src/features/Form/Form'
import { tenantDiscountsFormRequests, useTenantDiscounts } from '@src/api/tenants'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TenantDiscountInterface } from '@src/interfaces/tenants'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { AffectedCycles } from './AffectedCycles'

const AccountDiscountForm = () => {
  const { values } = useLapeContext<TenantDiscountInterface>()
  const params = useParams<{ id: string; discountId?: string; subscriptionId: string }>()

  const {
    error,
    isFetching,
    data: planDetails,
  } = useSubscriptionPlanDetails(params.subscriptionId)
  const { discounts } = useTenantDiscounts(params.id)

  useEffect(() => {
    if (!values.subscription_plan && params.subscriptionId) {
      values.subscription_plan = { id: Number(params.subscriptionId), name: '' }
    }
  }, [])

  if (error) {
    return <PageError error={error} />
  }

  if (!planDetails || isFetching) {
    return <PageLoading />
  }

  const isActive = values.status?.id === 'active'
  const disabledDays = [
    { before: new Date() },
    ...(discounts || [])
      .filter(discount => discount.id !== values.id)
      .map(discount => ({
        from: new Date(discount.start_date),
        to: new Date(discount.end_date),
      })),
  ]

  return (
    <PageWrapper>
      <PageHeader
        title={params.discountId ? 'Edit Discount' : 'Add Discount'}
        backUrl={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS, params)}
      />

      <PageBody>
        <Input label="Plan" value={planDetails.name} disabled />

        <Box mt="s-16">
          <Subheader>
            <Subheader.Title>Discount details</Subheader.Title>
          </Subheader>
          <LapeNewInput
            name="monthly_fee"
            label="Price per active employee per month"
            required
            disabled={isActive}
          />
        </Box>

        <Box mt="s-16">
          <Subheader>
            <Subheader.Title>
              When should discount be available for the tenant?
            </Subheader.Title>
          </Subheader>
          <InputGroup variant="horizontal">
            <LapeDatePickerInput
              label="Start date"
              name="start_date"
              message="Discount will be applied on the next billing cycle after this date"
              disabled={isActive}
              disabledDays={disabledDays}
              required
            />
            <LapeDatePickerInput
              label="End date"
              name="end_date"
              message="Discount will no longer be applied on the next billing cycle after this date"
              disabledDays={[
                ...disabledDays,
                values.start_date
                  ? { before: addDays(new Date(values.start_date), 1) }
                  : undefined,
              ]}
              required
            />
          </InputGroup>
        </Box>

        <Box mt="s-16">
          <AffectedCycles />
        </Box>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            params.discountId
              ? 'Discount successfully updated'
              : 'Discount successfully added'
          }
          onAfterSubmit={() => {
            goBack(pathToUrl(ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS, { id: params.id }))
          }}
        />
      </PageActions>
    </PageWrapper>
  )
}

export const AccountDiscount = connect(() => {
  const params = useParams<{ id: string; discountId?: string }>()

  return (
    <Form
      api={tenantDiscountsFormRequests}
      forceParams={{ id: params.discountId, tenantId: params.id }}
      disableLocalStorageCaching
    >
      <AccountDiscountForm />
    </Form>
  )
})
