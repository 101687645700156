import React, { useCallback, useEffect, useState } from 'react'
import { Copyable, Flex, Token, VStack, Widget } from '@revolut/ui-kit'
import { BulletPointTextArea } from './BulletPointTextArea'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import SectionStatus from './SectionStatus'
import { debounce } from 'lodash'

interface Props {
  talkingPoints: string
  isEnabledAI: boolean
  isLoading: boolean
  onTalkingPointsChange: (value: string) => void
  id: number | undefined
  disabled: boolean
}

export const TalkingPointsSection = ({
  talkingPoints,
  isEnabledAI,
  isLoading,
  id,
  disabled,
  onTalkingPointsChange,
}: Props) => {
  const [isEdited, setIsEdited] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  const debouncedAndThrottledOnChange = useCallback(
    debounce(onTalkingPointsChange, 1000),
    [],
  )

  useEffect(() => {
    setIsEdited(false)
  }, [id])

  useEffect(() => {
    if (!isLoading) {
      setIsTyping(false)
    }
  }, [isLoading])

  return (
    <Widget px="s-16" py="s-12">
      <VStack gap="s-6">
        <Flex justifyContent="space-between" alignItems="center">
          <SectionStatus
            title="Talking points"
            isGeneratedByAi={isEnabledAI}
            isEdited={isEdited}
            isLoading={isLoading || isTyping}
          />
          <Flex>
            <Copyable
              disabled={disabled}
              style={{ color: Token.color.greyTone20 }}
              value={talkingPoints || ''}
              labelButtonCopy="Copy"
              onCopy={() => {
                pushNotification({
                  value: 'Talking points are copied to clipboard',
                  duration: SUCCESS_DEFAULT_DURATION,
                  type: NotificationTypes.success,
                })
              }}
            />
          </Flex>
        </Flex>
        <BulletPointTextArea
          rows={10}
          disabled={disabled}
          value={talkingPoints || ''}
          onChange={(value: string) => {
            setIsEdited(value !== talkingPoints)
            setIsTyping(true)
            debouncedAndThrottledOnChange(value)
          }}
        />
      </VStack>
    </Widget>
  )
}
