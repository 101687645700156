import { Flex, Link, Text, Token, VStack } from '@revolut/ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { ReCaptchaHandle } from '@src/interfaces/reCaptcha'
import {
  CareersApplicationInterface,
  CareersApplicationStatusCode,
} from '@src/interfaces/careers'
import CareersPositionLocationsList from '@src/pages/Careers/CareersPosition/CareersPositionLocationsList'
import CareersApplicationCustomSections from './CareersApplicationCustomSections'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  useCareersSettings,
  useFetchCountries,
  useFetchPosition,
  useIsDiversityCollected,
} from '@src/api/recruitment/careers'
import { useParams } from 'react-router-dom'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { ReCaptcha } from '@components/ReCaptcha/ReCaptcha'
import { getIdFromPositionSlug } from '@src/pages/Careers/utils'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { CareersApplicationErrorPopup } from '@src/pages/Careers/CareersApplication/CareersApplicationErrorPopup'
import PageLoading from '@components/PageLoading/PageLoading'
import CareersApplicationPredefinedSections from '@src/pages/Careers/CareersApplication/CareersApplicationPredefinedSections'
import { PageWrapper } from '@components/Page/Page'
import ProgressCompletion from '@src/pages/Careers/CareersApplication/components/ProgressCompletion'
import { connect } from 'lape'
import ReEngageConsent from '@components/ReEngageConsent/ReEngageConsent'
import { useCheckPublicReEngagementConsentNeeded } from '@src/api/hiringProcess'
import { getCurrentCountryCode } from '@src/utils/timezones'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { AxiosError } from 'axios'

const CareersApplication = () => {
  const { id: slug } = useParams<{ id: string }>()
  const id = getIdFromPositionSlug(slug)

  const { values, errors, submit } = useLapeContext<CareersApplicationInterface>()
  const { data: position, isLoading } = useFetchPosition(id)
  const { data: countries } = useFetchCountries()
  const { data: settings } = useCareersSettings()
  const { data: consentNeededData, isLoading: isConsentLoading } =
    useCheckPublicReEngagementConsentNeeded(values.country?.id)
  const { data: diversitySettings } = useIsDiversityCollected(
    values.country?.id === undefined ? undefined : +values.country.id,
  )

  const [showError, setShowError] = useState<CareersApplicationStatusCode | null>(null)
  const captchaRef = useRef<ReCaptchaHandle>(null)
  const isCommercial = useIsCommercial()
  const analytics = useAnalytics()
  useSetDocumentTitle(
    position ? position.text : undefined,
    settings?.company_name ? `Careers at ${settings.company_name}` : undefined,
  )

  useEffect(() => {
    values.file = undefined
  }, [])

  useEffect(() => {
    if (isCommercial === false) {
      analytics.sendAnalyticsEvent(AnalyticsEvents.careers_application_new_flow_view)
    }
  }, [isCommercial])

  useEffect(() => {
    const region = getCurrentCountryCode()
    if (!region || values.country) {
      return
    }

    values.country = countries?.find(country => country.country_code === region)
  }, [countries])

  useEffect(() => {
    if (!consentNeededData) {
      return
    }

    if (!consentNeededData.is_consent_required) {
      delete values.consent_to_re_engage
    } else if (values.consent_to_re_engage === undefined) {
      values.consent_to_re_engage = false
    }
  }, [consentNeededData?.is_consent_required, values.consent_to_re_engage])

  const onSubmit = async () => {
    try {
      const token = await captchaRef.current?.verify()
      if (token) {
        values.captcha = token
      }
      await submit()

      if (isCommercial === false) {
        analytics.sendAnalyticsEvent(
          AnalyticsEvents.careers_application_new_flow_submit_success,
        )
      }
    } finally {
      captchaRef.current?.reset()
    }
  }

  const onError = (error: AxiosError) => {
    if (error?.response?.status !== 400) {
      setShowError(CareersApplicationStatusCode.Unknown)
    } else {
      const code = error?.response?.data?.code

      if (code && code !== CareersApplicationStatusCode.ValidationError) {
        setShowError(code)
      }
    }
  }

  if (isLoading) {
    return <PageLoading />
  }

  if (!position) {
    return null
  }

  return (
    <>
      <PageWrapper alignItems="center">
        <PageBody width="100%">
          <Text variant="h1" textAlign="center" display="block" mb="s-16">
            You are applying for {position?.text}
          </Text>
          <Flex justifyContent="center" mx={{ all: 0, lg: '-s-32' }}>
            <CareersPositionLocationsList locations={position.locations} />
          </Flex>

          <VStack gap="s-32" mt="s-32">
            <CareersApplicationPredefinedSections
              position={position}
              countries={countries}
              showDiversity={!!diversitySettings?.is_diversity_collected}
            />

            <CareersApplicationCustomSections />
          </VStack>

          <ReEngageConsent
            loading={isConsentLoading}
            consentNeeded={consentNeededData?.is_consent_required}
            checked={!!values.consent_to_re_engage}
            onCheck={checked => {
              values.consent_to_re_engage = checked
            }}
            width="100%"
            textAlign="center"
            mt="s-32"
          />

          <Flex alignItems="center" flexDirection="column" mt="s-32">
            <ReCaptcha
              onVerify={value => {
                values.captcha = value
              }}
              name="captcha"
              error={errors?.captcha}
              ref={captchaRef}
            />
          </Flex>
          {settings?.data_privacy_notice_url && (
            <Text use="div" variant="small" mt="s-32" textAlign="center">
              Refer to our{' '}
              <Link
                href={settings.data_privacy_notice_url}
                target="_blank"
                color={Token.color.blue}
              >
                Data Privacy Statement for Candidates
              </Link>{' '}
              for details on our data handling practices during your application.
            </Text>
          )}
          <CareersApplicationErrorPopup
            open={!!showError}
            errorCode={showError || CareersApplicationStatusCode.Unknown}
            onClose={() => setShowError(null)}
          />
        </PageBody>
        <PageActions bottom={84}>
          <NewSaveButtonWithPopup
            useValidator
            onClick={onSubmit}
            errorHandler={onError}
            successText="Thank you for your application!"
            successDescription="We will be in touch with you shortly."
            onAfterSubmit={() => {
              navigateReplace(pathToUrl(PUBLIC_ROUTES.CAREERS.JOB_LIST))
            }}
            popupVariant="success-result"
          >
            Submit application
          </NewSaveButtonWithPopup>
        </PageActions>
      </PageWrapper>
      <ProgressCompletion />
    </>
  )
}

export default connect(CareersApplication)
