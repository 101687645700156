import { API, selectorKeys, externalSelectors } from '../constants/api'
import { apiWithoutHandling } from './index'
import { BaseOption, BaseOptionId, OptionInterface } from '../interfaces/selectors'
import { QueryKeyT, useFetch } from '@src/utils/reactQuery'
import { ApiHandlerInterface, ApiVersion } from '@src/interfaces'
import { UseQueryOptions } from 'react-query'
import { FilterByInterface, SortByInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import { AxiosError } from 'axios'

export const getSelectors = async <
  Id extends BaseOptionId,
  Option extends BaseOption<Id> = BaseOption<Id>,
>(
  type: selectorKeys,
  apiVersion?: ApiVersion,
  apiHandler = apiWithoutHandling,
) => {
  const isExternal = externalSelectors.includes(type)
  return apiHandler.get<{ options: Option[] }>(
    API.SELECTORS,
    {
      params: {
        name: type,
      },
    },
    isExternal ? 'v2' : apiVersion,
    isExternal,
  )
}

interface GetSelectorsWithFiltersArgs {
  readonly type: selectorKeys
  readonly filterBy?: FilterByInterface[]
  readonly sortBy?: SortByInterface[]
  readonly apiVersion?: ApiVersion
  readonly apiHandler?: ApiHandlerInterface
}

export const getSelectorsWithFilters = async <Id extends string | number>({
  type,
  apiVersion,
  filterBy,
  apiHandler = apiWithoutHandling,
}: GetSelectorsWithFiltersArgs) => {
  const isExternal = externalSelectors.includes(type)

  return apiHandler.get<{ options: OptionInterface<Id>[] }>(
    API.SELECTORS,
    {
      params: {
        name: type,
        ...groupFiltersIntoURLQuery(filterBy),
      },
    },
    isExternal ? 'v2' : apiVersion,
    isExternal,
  )
}

export type UseGetSelectorsQueryOptions<T> = UseQueryOptions<
  { options: T[] },
  AxiosError,
  { options: T[] },
  QueryKeyT
>

export const useGetSelectors = <T = OptionInterface>(
  type: selectorKeys | null,
  apiVersion?: ApiVersion,
  queryOptions?: UseGetSelectorsQueryOptions<T>,
) => {
  const isExternal = type ? externalSelectors.includes(type) : false
  const context = useFetch<{ options: T[] }>(
    type ? API.SELECTORS : null,
    isExternal ? 'v2' : apiVersion,
    {
      params: {
        name: type,
      },
    },
    undefined,
    queryOptions || undefined,
    isExternal,
  )

  return { ...context, data: context.data?.options }
}

interface UseGetSelectorsWithFiltersProps<T> {
  type: selectorKeys | null
  apiVersion?: ApiVersion
  queryOptions?: UseGetSelectorsQueryOptions<T>
  filters?: FilterByInterface[]
  sortBy?: SortByInterface[]
}

export const useGetSelectorsWithFilters = <T = OptionInterface>({
  type,
  apiVersion,
  queryOptions,
  filters,
  sortBy,
}: UseGetSelectorsWithFiltersProps<T>) => {
  const isExternal = type ? externalSelectors.includes(type) : false
  const context = useFetch<{ options: T[] }>(
    type ? API.SELECTORS : null,
    isExternal ? 'v2' : apiVersion,
    {
      params: {
        name: type,
        ...filterSortPageIntoQuery(sortBy, filters, undefined),
      },
    },
    undefined,
    queryOptions || undefined,
    isExternal,
  )

  return { ...context, data: context.data?.options }
}
