import { GrowthPlanDecision, GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { Statuses } from '@src/interfaces'
import isPast from 'date-fns/isPast'

interface GrowthPlanModel {
  isEditable: (growthPlan: GrowthPlanInterface) => boolean
  isDraft: (growthPlan: GrowthPlanInterface) => boolean
  isApproved: (growthPlan: GrowthPlanInterface) => boolean
  isCompleted: (growthPlan: GrowthPlanInterface) => boolean
  isMissed: (growthPlan: GrowthPlanInterface) => boolean
  isReadyForApproval: (growthPlan: GrowthPlanInterface) => boolean
  isReadyForCompletion: (growthPlan: GrowthPlanInterface) => boolean
  hasRequestedChanges: (growthPlan: GrowthPlanInterface) => boolean
  hasNominationReminder: (growthPlan: GrowthPlanInterface) => boolean
}

const isEditable = (growthPlan: GrowthPlanInterface) =>
  growthPlan.status !== Statuses.archived &&
  !isCompleted(growthPlan) &&
  !isMissed(growthPlan)

const isDraft = (growthPlan: GrowthPlanInterface) => growthPlan.status === Statuses.draft

const isApproved = (growthPlan: GrowthPlanInterface) =>
  growthPlan.status === Statuses.approved

const isCompleted = (growthPlan: GrowthPlanInterface) =>
  growthPlan.decision === GrowthPlanDecision.Completed

const isMissed = (growthPlan: GrowthPlanInterface) =>
  growthPlan.decision === GrowthPlanDecision.Missed

const isReadyForApproval = (growthPlan: GrowthPlanInterface) =>
  growthPlan.status === Statuses.pending

const isReadyForCompletion = (growthPlan: GrowthPlanInterface) =>
  growthPlan.status === Statuses.approved &&
  growthPlan.decision === GrowthPlanDecision.Ongoing &&
  isPast(new Date(growthPlan.deadline))

const hasRequestedChanges = (growthPlan: GrowthPlanInterface) =>
  growthPlan.status === Statuses.requires_changes

const hasNominationReminder = (growthPlan: GrowthPlanInterface) =>
  !!growthPlan.remind_to_nominate

const createGrowthPlanModel = (): GrowthPlanModel => {
  return {
    isEditable,
    isDraft,
    isApproved,
    isCompleted,
    isMissed,
    isReadyForApproval,
    isReadyForCompletion,
    hasRequestedChanges,
    hasNominationReminder,
  }
}

export const growthPlanModel = createGrowthPlanModel()
