import React from 'react'
import { Action, StatusWidget } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageWidgetWrapper } from './PageWidgetWrapper'
import { PageErrorTitle } from './PageErrorTitle'

export const PageNoAccessWidget = () => {
  return (
    <PageWidgetWrapper>
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D020.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D020@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D020@3x.png',
          }}
        />
        <StatusWidget.Title>
          <PageErrorTitle error={{ type: 'forbidden' }} />
        </StatusWidget.Title>
        <StatusWidget.Actions>
          <Action use={InternalLink} to={ROUTES.MAIN}>
            Go to dashboard
          </Action>
        </StatusWidget.Actions>
      </StatusWidget>
    </PageWidgetWrapper>
  )
}
