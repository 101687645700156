import * as Sentry from '@sentry/react'

import { isDev } from '@src/utils'

export const captureError = (
  error: unknown,
  options?: {
    tags: Record<string, string | number | null | undefined>
    extra?: Record<string, unknown>
    severity?: Sentry.SeverityLevel
  },
) => {
  if (isDev()) {
    console.error('Sentry.captureException called with arguments:', { error, options })
  }

  Sentry.withScope(scope => {
    if (options?.severity) {
      scope.setLevel(options.severity)
    }
    Sentry.captureException(error, options)
  })
}
