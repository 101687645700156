import React from 'react'
import { Action, StatusWidget } from '@revolut/ui-kit'

import { PageWidgetWrapper } from './PageWidgetWrapper'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageErrorTitle } from './PageErrorTitle'

interface PageNotFoundWidgetProps {
  noLink?: boolean
}

export const PageNotFoundWidget = ({ noLink }: PageNotFoundWidgetProps) => {
  return (
    <PageWidgetWrapper>
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
          }}
        />
        <StatusWidget.Title>
          <PageErrorTitle error={{ type: 'resource_not_found' }} />
        </StatusWidget.Title>
        <StatusWidget.Description>
          The URL may be misspelled or the page you're looking for is no longer available.
        </StatusWidget.Description>
        {!noLink && (
          <StatusWidget.Actions>
            <Action use={InternalLink} to={ROUTES.MAIN}>
              Go to dashboard
            </Action>
          </StatusWidget.Actions>
        )}
      </StatusWidget>
    </PageWidgetWrapper>
  )
}
