import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AppError } from '../../parseError'

interface PageErrorTitleProps {
  error: AppError
}

export const PageErrorTitle = ({ error }: PageErrorTitleProps) => {
  switch (error.type) {
    case 'forbidden':
      return (
        <FormattedMessage
          id="error.page.noPermission"
          defaultMessage="You don't have the permission to view this page"
        />
      )
    case 'bad_request':
      return (
        <FormattedMessage id="error.page.invalidData" defaultMessage="Invalid data" />
      )
    case 'resource_not_found':
      return (
        <FormattedMessage
          id="error.page.resourceNotFound"
          defaultMessage="Page not found"
        />
      )
    default:
      return (
        <FormattedMessage
          id="error.page.somethingWentWrong"
          defaultMessage="Something went wrong"
        />
      )
  }
}
