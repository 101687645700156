import React from 'react'
import {
  Badge,
  DataPoint,
  Dropdown,
  Flex,
  H5,
  Highlights,
  IconButton,
  Skeleton,
  Spinner,
  Tag,
  Text,
  TextSkeleton,
  Token,
  useDropdown,
} from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { MeetingNotesFeedback, SummaryStatus } from '@src/interfaces/meetings'
import { differenceInMinutes, format, isPast } from 'date-fns'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { deleteMeetingNotesFeedback } from '@src/api/meetings'
import { PermissionTypes } from '@src/store/auth/types'
import { meetingDuration } from '../utils/meetingDuration'
import { getPrivateContentItemsByRole } from '../utils/getContentItemsByRole'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { FinalGrade } from '@src/interfaces/performance'
import { getInverseColorsAndGrade } from '@src/components/PerformanceGrade/PerformanceGrade'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { GradesMapInterface } from '@src/utils/grades'

interface Props {
  meetingNotes: MeetingNotesFeedback | undefined
  isLoading: boolean
  isLoadingPerformanceRating: boolean
  meetingId?: number
  refetchMeetingNotesFeedback: VoidFunction
  onPerformanceRatingChange: (value: { name: string; value: FinalGrade }) => void
  disabled: boolean
}

const NotesSkeleton = () => (
  <Flex p="s-16" alignItems="center" justifyContent="space-between">
    <Flex gap="s-48" justifyContent="space-between">
      <Flex gap="s-4" flexDirection="column" alignItems="flex-start">
        <Flex gap="s-8" alignItems="center">
          <TextSkeleton size={20} variant="heading3" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>

        <Flex gap="s-8" justifyContent="flex-start">
          <TextSkeleton size={14} variant="body2" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>
      </Flex>

      <Flex gap="s-8" flexDirection="column">
        <Flex gap="s-8" alignItems="center">
          <Skeleton height={24} width={150} />
          <TextSkeleton size={14} variant="body2" />
        </Flex>

        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex alignItems="center" gap="s-6">
      <TextSkeleton size={14} variant="body2" />
      <TextSkeleton size={14} variant="body2" />
    </Flex>
  </Flex>
)

export const NotesHeader = ({
  meetingNotes,
  isLoading,
  isLoadingPerformanceRating,
  meetingId,
  onPerformanceRatingChange,
  refetchMeetingNotesFeedback,
  disabled,
}: Props) => {
  const user = useSelector(selectUser)
  const performanceRatingData = getPrivateContentItemsByRole(
    meetingNotes,
    'performance_rating',
  )?.content

  const { data: grades, isLoading: isLoadingGrades } = useGetSelectors(
    selectorKeys.grades,
  )

  const gradesMap = grades?.reduce<GradesMapInterface>((acc, grade) => {
    acc[grade.id] = grade.name
    return acc
  }, {})

  const performanceRatingOptions = performanceRatingData?.content
  const performanceRatingValue = performanceRatingData?.value

  const isManager = meetingNotes?.manager.id === user.id
  const actionsDropdown = useDropdown()
  const performanceRatingDropdown = useDropdown()

  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()
  const canChangeNotes = meetingNotes?.field_options.permissions?.includes(
    PermissionTypes.ChangeOneToOneFeedbackItem,
  )

  const handleDelete = () => {
    confirmationPopup.show({
      showHeader: false,
      body: (
        <H5 color={Token.color.foreground}>
          Are you sure you want to delete this notes?
        </H5>
      ),
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: async () => {
        if (meetingNotes?.id && meetingId) {
          await deleteMeetingNotesFeedback(meetingId, meetingNotes.id)
          showStatusPopup({
            title: 'Notes deleted',
            status: 'success',
          })
          refetchMeetingNotesFeedback()
        }
      },
    })
  }

  if (isLoading || isLoadingGrades) {
    return <NotesSkeleton />
  }

  if (!meetingNotes) {
    return null
  }

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex gap="s-48" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-start">
          <Highlights>
            <DataPoint>
              <DataPoint.Value>
                <Flex alignItems="center" gap="s-4">
                  <Text variant="heading3">
                    {format(new Date(meetingNotes.meeting.start), 'MMM d')}
                  </Text>
                  <Badge bg={Token.color.searchBackground} color={Token.color.grey50}>
                    {isPast(new Date(meetingNotes.meeting.end)) ? 'Previous' : 'Future'}
                  </Badge>
                </Flex>
              </DataPoint.Value>
              <DataPoint.Label>
                <Flex alignItems="center" gap="s-4">
                  <Text variant="body2">
                    {meetingDuration(
                      meetingNotes.meeting.start,
                      meetingNotes.meeting.end,
                    )}
                  </Text>
                  <Text variant="body2" color={Token.color.grey50}>
                    {differenceInMinutes(
                      new Date(meetingNotes.meeting.end),
                      new Date(meetingNotes.meeting.start),
                    )}
                    min
                  </Text>
                </Flex>
              </DataPoint.Label>
            </DataPoint>
          </Highlights>
        </Flex>

        {meetingNotes.recorded_by && (
          <Highlights>
            <DataPoint>
              <DataPoint.Value>
                <Flex gap="s-4" alignItems="center">
                  <UserWithAvatar compact {...meetingNotes.recorded_by} />
                  <Text variant="heading3">{meetingNotes.recorded_by.full_name}</Text>
                </Flex>
              </DataPoint.Value>
              <DataPoint.Label>
                <Text variant="body2">Notes taken by</Text>
              </DataPoint.Label>
            </DataPoint>
          </Highlights>
        )}

        {isManager && (
          <Highlights>
            <DataPoint>
              <DataPoint.Value>
                <Flex
                  {...(disabled ? {} : performanceRatingDropdown.getAnchorProps())}
                  style={{ cursor: disabled ? 'default' : 'pointer' }}
                  gap="s-4"
                  alignItems="center"
                >
                  <Text
                    variant="heading3"
                    color={
                      getInverseColorsAndGrade(performanceRatingValue?.value, gradesMap)
                        ?.color
                    }
                  >
                    {getInverseColorsAndGrade(performanceRatingValue?.value, gradesMap)
                      ?.grade || 'Select Rating'}
                  </Text>
                  {!disabled && (
                    <IconButton size={16} useIcon="ChevronDown" aria-label="Close" />
                  )}
                  {isLoadingPerformanceRating && <Spinner size={16} />}
                </Flex>

                <Dropdown autoClose {...performanceRatingDropdown.getTargetProps()}>
                  {performanceRatingOptions?.map(rating => {
                    return (
                      <Dropdown.Item
                        key={rating.value}
                        use="button"
                        onClick={() => onPerformanceRatingChange(rating)}
                      >
                        <Text
                          color={getInverseColorsAndGrade(rating.value, gradesMap)?.color}
                        >
                          {getInverseColorsAndGrade(rating.value, gradesMap)?.grade}
                        </Text>
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown>
              </DataPoint.Value>
              <DataPoint.Label>Grade</DataPoint.Label>
            </DataPoint>
          </Highlights>
        )}
      </Flex>

      <Flex alignItems="center" gap="s-8">
        {meetingNotes.enable_ai &&
        meetingNotes.summary_status === SummaryStatus.COMPLETED ? (
          <Tag color={Token.color.lightBlue} variant="outlined" useIcon="Cleaning">
            AI Transcript
          </Tag>
        ) : (
          <Tag color={Token.color.greyTone50} variant="outlined" useIcon="Pencil">
            Manual
          </Tag>
        )}
        {canChangeNotes && (
          <IconButton
            useIcon="Ellipsis"
            aria-label="Close"
            {...actionsDropdown.getAnchorProps()}
          />
        )}
        <Dropdown {...actionsDropdown.getTargetProps()}>
          <Dropdown.Item
            use="button"
            useIcon="Delete"
            color={Token.color.danger}
            onClick={handleDelete}
          >
            Delete notes
          </Dropdown.Item>
        </Dropdown>
      </Flex>
    </Flex>
  )
}
