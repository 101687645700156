import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar, DataPoint, Flex, Highlights, Spacer, Tag, Token } from '@revolut/ui-kit'
import { SeniorityProgression } from '@src/features/GrowthPlans/GrowthPlan/components/SeniorityProgression'
import { formatDate } from '@src/utils/format'
import Table from '@components/TableV2/Table'
import { GrowthPlanActions } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/GrowthPlanActions'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useCanCreateGrowthPlans } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { selectUser } from '@src/store/auth/selectors'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  refetch: VoidFunction
  refetchApproval: () => Promise<void>
}

export const GrowthPlanHeader = ({
  employee,
  growthPlan,
  refetch,
  refetchApproval,
}: Props) => {
  const canCreateGrowthPlans = useCanCreateGrowthPlans(employee)
  const currentUser = useSelector(selectUser)

  return (
    <Flex gap="s-32">
      <Avatar useIcon="Flag" size={48} />
      <Highlights>
        <DataPoint>
          <DataPoint.Value>
            <Flex gap="s-8" alignItems="center">
              {growthPlan.target_specialisation.name}
              <Tag color={Token.color.grey50_20}>
                <SeniorityProgression
                  values={[
                    growthPlan.current_seniority.name,
                    growthPlan.target_seniority.name,
                  ]}
                />
              </Tag>
            </Flex>
          </DataPoint.Value>
          <DataPoint.Label>Target role</DataPoint.Label>
        </DataPoint>
        <DataPoint>
          <DataPoint.Value>{formatDate(growthPlan.deadline)}</DataPoint.Value>
          <DataPoint.Label>Timeline</DataPoint.Label>
        </DataPoint>
      </Highlights>
      <Spacer />
      {canCreateGrowthPlans && currentUser.id !== employee.id && (
        <Table.Widget.MoreBar maxCount={2}>
          <GrowthPlanActions
            employee={employee}
            growthPlan={growthPlan}
            refetch={refetch}
            refetchApproval={refetchApproval}
          />
        </Table.Widget.MoreBar>
      )}
    </Flex>
  )
}
