import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { SelectPlan } from './SelectPlan'
import { Checkout } from './Checkout'
import { SubscriptionAndPayments } from './SubscriptionAndPayments'
import { SubscriptionPlanProvider } from './SubscriptionPlanProvider'
import { InvoiceDetails } from './InvoiceDetails'
import { MainLayout } from '@src/features/MainLayout/MainLayout'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'

export const Plans = () => {
  return (
    <>
      <Switch>
        <Route path={[ROUTES.PLANS.SELECT_PLAN, ROUTES.PLANS.CHECKOUT]}>
          <PlanSelectionRouter />
        </Route>
        <Route exact path={[ROUTES.PLANS.PAYMENT_DETAILS, ROUTES.PLANS.INVOICES]}>
          <MainLayout>
            <SubscriptionAndPayments />
          </MainLayout>
        </Route>
        <Route exact path={ROUTES.PLANS.INVOICE_DETAILS}>
          <MainLayout>
            <InvoiceDetails />
          </MainLayout>
        </Route>
        <Route>
          <PageNotFoundWidget />
        </Route>
      </Switch>
    </>
  )
}

const PlanSelectionRouter = () => {
  return (
    <SubscriptionPlanProvider>
      <Layout>
        <Switch>
          <Route exact path={ROUTES.PLANS.SELECT_PLAN}>
            <SelectPlan />
          </Route>
          <Route exact path={ROUTES.PLANS.CHECKOUT}>
            <Checkout />
          </Route>
        </Switch>
      </Layout>
    </SubscriptionPlanProvider>
  )
}
