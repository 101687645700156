import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { useGetOffboardingDashboard } from '@src/api/offboarding'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ROUTES } from '@src/constants/routes'
import { useOffboardingParams } from './common'
import { Dashboard } from './Dashboard'
import Payments from './Payments'
import { PageError } from '@src/features/Errors/components/Page/PageError'
import AccessRetention from './AccessRetention'
import { Tickets } from './Tickets'
import SendEmail from './SendEmail'

export const Offboarding = () => {
  const params = useOffboardingParams()
  const dashboard = useGetOffboardingDashboard(params.employeeId)

  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [history.location.pathname])

  if (dashboard.isError) {
    return <PageError error={dashboard.error} />
  }

  if (dashboard.isLoading || !dashboard.data) {
    return <PageLoading />
  }

  return (
    <Switch>
      <Route path={ROUTES.FORMS.OFFBOARDING.DASHBOARD} exact>
        <Dashboard data={dashboard.data} refreshDashboard={dashboard.refetch} />
      </Route>
      <Route path={ROUTES.FORMS.OFFBOARDING.PAYMENTS} exact>
        <Payments data={dashboard.data} refreshDashboard={dashboard.refetch} />
      </Route>
      <Route path={ROUTES.FORMS.OFFBOARDING.ACCESS_RETENTION} exact>
        <AccessRetention data={dashboard.data} refreshDashboard={dashboard.refetch} />
      </Route>
      <Route path={ROUTES.FORMS.OFFBOARDING.TICKETS} exact>
        <Tickets data={dashboard.data} refreshDashboard={dashboard.refetch} />
      </Route>
      <Route path={ROUTES.FORMS.OFFBOARDING.SEND_EMAIL} exact>
        <SendEmail data={dashboard.data} refreshDashboard={dashboard.refetch} />
      </Route>
    </Switch>
  )
}
