import React, { useRef } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ROUTES } from '@src/constants/routes'
import { Survey } from './Survey'
import { ScheduledForClosure } from './ScheduledForClosure'
import { Confirmation } from './Confirmation'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PageError } from '@src/features/Errors/components/Page/PageError'
import { useAccountClosureInfo } from '@src/api/plans'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { navigateReplace } from '@src/actions/RouterActions'
import { PageNoAccessWidget } from '@src/features/Errors/components/Page/PageNoAccessWidget'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'

export const CloseAccount = () => {
  const { data, isLoading, error } = useAccountClosureInfo()
  const permissions = useSelector(selectPermissions)

  const redirectGuardEnabled = useRef(true)

  const isSurveyRoute = useRouteMatch(ROUTES.SETTINGS.CLOSE_ACCOUNT.SURVEY)
  const isConfirmationRoute = useRouteMatch(ROUTES.SETTINGS.CLOSE_ACCOUNT.CONFIRMATION)
  const isScheduledForClosureRoute = useRouteMatch(
    ROUTES.SETTINGS.CLOSE_ACCOUNT.SCHEDULED_FOR_CLOSURE,
  )

  const canCloseAccount = permissions.includes(PermissionTypes.CloseAccount)

  if (!canCloseAccount) {
    return <PageNoAccessWidget />
  }

  if (error) {
    return <PageError error={error} />
  }

  if (isLoading) {
    return <PageLoading />
  }

  if (redirectGuardEnabled.current) {
    if ((!data || data?.state.id === 'cancelled') && !isSurveyRoute) {
      navigateReplace(ROUTES.SETTINGS.CLOSE_ACCOUNT.SURVEY)
      return null
    }

    if (data?.state.id === 'pending' && !isConfirmationRoute) {
      navigateReplace(ROUTES.SETTINGS.CLOSE_ACCOUNT.CONFIRMATION)
      return null
    }

    if (data?.state.id === 'confirmed' && !isScheduledForClosureRoute) {
      navigateReplace(ROUTES.SETTINGS.CLOSE_ACCOUNT.SCHEDULED_FOR_CLOSURE)
      return null
    }

    redirectGuardEnabled.current = false
  }

  return (
    <Switch>
      <Route path={ROUTES.SETTINGS.CLOSE_ACCOUNT.SURVEY}>
        <Survey />
      </Route>
      <Route path={ROUTES.SETTINGS.CLOSE_ACCOUNT.CONFIRMATION}>
        <Confirmation />
      </Route>
      <Route path={ROUTES.SETTINGS.CLOSE_ACCOUNT.SCHEDULED_FOR_CLOSURE}>
        <ScheduledForClosure />
      </Route>
      <Route>
        <PageNotFoundWidget />
      </Route>
    </Switch>
  )
}
