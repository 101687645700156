import {
  Flex,
  AvatarSkeleton,
  TextSkeleton,
  VStack,
  Separator,
  Box,
} from '@revolut/ui-kit'
import React from 'react'

export const HeaderSummarySkeleton = () => (
  <Flex p="s-16" gap="s-16" style={{ flexGrow: 1 }}>
    <Flex ml="s-12" mt="s-16" mb="s-4" gap="s-16" alignItems="center">
      <AvatarSkeleton size={76} />
      <Flex minWidth={110} gap="s-2" flexDirection="column">
        <TextSkeleton size={6} variant="heading3" />
        <TextSkeleton size={8} variant="caption" />
      </Flex>
    </Flex>

    <VStack>
      <TextSkeleton py="s-12" pl="s-12" size={14} variant="emphasis2" />

      <Flex alignItems="center" flexDirection="row" gap="s-16">
        <Flex gap="s-16" m="s-16" alignItems="center">
          <AvatarSkeleton size={40} />
          <TextSkeleton size={5} variant="emphasis1" />
        </Flex>
        <Flex gap="s-16" mx="s-16" alignItems="center">
          <AvatarSkeleton size={40} />
          <TextSkeleton size={7} variant="emphasis1" />
        </Flex>
        <Box height={60}>
          <Separator orientation="vertical" />
        </Box>
        <Flex
          minWidth={110}
          mx="s-16"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <TextSkeleton size={4} variant="heading3" />
          <TextSkeleton size={5} variant="caption" />
        </Flex>
        <Flex
          minWidth={110}
          mx="s-16"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <TextSkeleton size={4} variant="heading3" />
          <TextSkeleton size={16} variant="caption" />
        </Flex>
      </Flex>
    </VStack>
  </Flex>
)
