import React, { useContext, useMemo, useState } from 'react'
import {
  Action,
  Box,
  Button,
  DetailsCell,
  Group,
  Header,
  Side,
  Subheader,
  Text,
  Widget,
} from '@revolut/ui-kit'
import { AvailableInterviewerSlot, SchedulingType } from '@src/interfaces/interviewTool'
import { sendInterviewInvite } from '@src/api/recruitment/interviewerScheduling'
import {
  getFullTime,
  getTimeZoneId,
  getUtcCustomDate,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { VStack } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import PreviewSlots from '@src/pages/Forms/Candidate/ScheduleSidebar/PreviewSlots'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { ExtensionApiHandlerContext } from '@src/utils/extension'
import { getAvatarUrl } from '@src/utils/employees'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

type Props = {
  roundId: number
  selectedSlots: AvailableInterviewerSlot[]
  onBack: () => void
  onSubmit: () => void
  schedulingType: SchedulingType
  isPrepCall?: boolean
  onEditEmail: () => void
  onEditDetails: () => void
}

const PreviewScheduleSidebarForm = ({
  roundId,
  onBack,
  onSubmit,
  selectedSlots,
  schedulingType,
  isPrepCall,
  onEditEmail,
  onEditDetails,
}: Props) => {
  const user = useSelector(selectUser)
  const openNewTab = useOpenNewTab()
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const showStatusPopup = useShowStatusPopup()
  const apiHandler = useContext(ExtensionApiHandlerContext)

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)
  const utcCustomDate = getUtcCustomDate(timeZoneId, values.custom_date)

  const timeZoneName = values.scheduling_timezone?.name

  const [isSending, setIsSending] = useState(false)

  const selectedSlotIds = useMemo(
    () => selectedSlots.map(item => item.id),
    [selectedSlots],
  )

  const formattedDuration = useMemo(() => {
    const duration = values.duration || values.interview_stage?.duration
    const durationUnit = values.duration_unit?.id || values.interview_stage?.duration_unit

    if (!duration || !durationUnit) {
      return '-'
    }

    return getDuration(duration, durationUnit)
  }, [values])

  const onSendInvite = async () => {
    if (!values.interview_stage?.id) {
      return
    }

    setIsSending(true)

    const commonProps = {
      scheduling_type: schedulingType,
      interview_stage: { id: values.interview_stage.id },
      scheduling_timezone: { id: values.scheduling_timezone!.id! },
      duration: values.duration,
      duration_unit: values.duration_unit,
      calendar_id: values.calendar?.calendar_id,
      calendar_name: values.calendar?.summary,
      email_content: values.email_body,
      email_subject: values.subject,
      sender_type: values.is_candidate_involved ? values.sender_type : 'user',
      recipients_cc: values.recipients_cc,
      recipients_bcc: values.recipients_bcc,
      attachments: values.attachments || [],
      additional_interviewers: values.additional_interviewers || [],
      is_adhoc: isPrepCall,
      summary: values.summary,
      meeting_link: values.meeting_link,
      event_title: values.event_title,
    }
    try {
      await sendInterviewInvite(
        values.interview_stage?.id,
        roundId,
        schedulingType === SchedulingType.classic
          ? { ...commonProps, proposed_event_ids: selectedSlotIds }
          : {
              ...commonProps,
              interviewer: values.interviewer,
              event_date_time: utcCustomDate,
              is_candidate_involved: values.is_candidate_involved,
            },
        apiHandler,
      )

      setIsSending(false)
      showStatusPopup({
        title: 'Interview scheduled successfully',
      })
      onSubmit()
    } catch (e) {
      setIsSending(false)
      showStatusPopup({
        title: 'Cannot schedule the interview',
        description: getStringMessageFromError(e),
        status: 'error',
        actions: (
          <Button
            onClick={() =>
              openNewTab(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS, { id: user.id }),
              )
            }
          >
            Enable email integration
          </Button>
        ),
      })
      onBack()
    }
  }

  const canSendInvite =
    (!!values.subject && !!values.email_body) || !values.is_candidate_involved

  return (
    <>
      <Header variant="item">
        <Header.BackButton aria-label="Back" onClick={onBack} />
        <Header.Title aria-label="sidebar-title">Preview</Header.Title>
        <Header.Description>
          Confirm the details before sending the invite
        </Header.Description>
      </Header>
      <Box>
        <Group>
          {!isPrepCall && (
            <DetailsCell>
              <DetailsCell.Title>Stage</DetailsCell.Title>
              <DetailsCell.Content>{values.interview_stage?.title}</DetailsCell.Content>
            </DetailsCell>
          )}
          <DetailsCell>
            <DetailsCell.Title>Duration</DetailsCell.Title>
            <DetailsCell.Content>{formattedDuration}</DetailsCell.Content>
          </DetailsCell>

          <DetailsCell>
            <DetailsCell.Title>Time zone</DetailsCell.Title>
            <DetailsCell.Content>{timeZoneName || timeZoneId}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Calendar</DetailsCell.Title>
            <DetailsCell.Content>{values.calendar?.summary || '-'}</DetailsCell.Content>
          </DetailsCell>
          {!!values.additional_interviewers?.length && (
            <DetailsCell>
              <DetailsCell.Title>Additional interviewers</DetailsCell.Title>
              <DetailsCell.Content>
                <VStack gap="s-16" align="end">
                  {values.additional_interviewers.map(item => (
                    <UserWithAvatar
                      key={item.id}
                      full_name={item.name}
                      id={item.id}
                      avatar={getAvatarUrl(item.avatar)}
                    />
                  ))}
                </VStack>
              </DetailsCell.Content>
            </DetailsCell>
          )}
        </Group>
      </Box>
      {schedulingType === SchedulingType.custom &&
        values.custom_date &&
        values.interviewer && (
          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Custom scheduling time</Subheader.Title>
              <Subheader.Side>
                <Action onClick={onEditDetails}>Edit</Action>
              </Subheader.Side>
            </Subheader>
            <Group>
              <DetailsCell>
                <DetailsCell.Title>Time</DetailsCell.Title>
                <DetailsCell.Content>
                  {getFullTime(values.custom_date)}
                </DetailsCell.Content>
              </DetailsCell>
              <DetailsCell>
                <DetailsCell.Title>Lead interviewer</DetailsCell.Title>
                <DetailsCell.Content>
                  <UserWithAvatar
                    full_name={values.interviewer.name}
                    id={values.interviewer.id}
                    avatar={getAvatarUrl(values.interviewer.avatar)}
                  />
                </DetailsCell.Content>
              </DetailsCell>
              <DetailsCell>
                <DetailsCell.Title>Invite candidate</DetailsCell.Title>
                <DetailsCell.Content>
                  {values.is_candidate_involved ? 'Yes' : 'No'}
                </DetailsCell.Content>
              </DetailsCell>
            </Group>
          </Box>
        )}

      {(values.event_title || values.meeting_link) && (
        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Event details</Subheader.Title>
            <Subheader.Side>
              <Action onClick={onEditDetails}>Edit</Action>
            </Subheader.Side>
          </Subheader>
          <Group>
            <DetailsCell>
              <DetailsCell.Title>Event title</DetailsCell.Title>
              <DetailsCell.Content>{values.event_title}</DetailsCell.Content>
            </DetailsCell>
            <DetailsCell>
              <DetailsCell.Title>Meeting link</DetailsCell.Title>
              <DetailsCell.Content>{values.meeting_link}</DetailsCell.Content>
            </DetailsCell>
          </Group>
        </Box>
      )}

      {schedulingType === SchedulingType.classic && (
        <PreviewSlots
          onBack={onEditDetails}
          roundId={roundId}
          selectedSlots={selectedSlots}
          label="Preferred slots"
        />
      )}

      {isPrepCall && (
        <Box mt="s-16">
          <LapeNewTextArea name="summary" label="Notes" message="For internal use only" />
        </Box>
      )}

      {values.email_body && values.is_candidate_involved && (
        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Email preview</Subheader.Title>
            <Subheader.Side>
              <Action onClick={onEditEmail} aria-label="Edit email">
                Edit
              </Action>
            </Subheader.Side>
          </Subheader>
          <Widget p="s-16">
            <Text
              aria-label="email-preview"
              dangerouslySetInnerHTML={{ __html: values.email_body }}
            />
          </Widget>
        </Box>
      )}

      <Side.Actions>
        <Button
          elevated
          onClick={onSendInvite}
          pending={isSending}
          disabled={isSending || !canSendInvite}
        >
          Schedule interview
        </Button>
      </Side.Actions>
    </>
  )
}

const PreviewScheduleSidebar = (props: Props) => {
  return <PreviewScheduleSidebarForm {...props} />
}

export default PreviewScheduleSidebar
