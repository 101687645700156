import { api, apiWithoutHandling } from '@src/api'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions as UseInfiniteQueryOptionsBase,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions as UseQueryOptionsBase,
} from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'
import {
  ApiHandlerInterface,
  ApiVersion,
  UseFetchResult,
  UseInfiniteFetchResult,
} from '@src/interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import { useContext } from 'react'
import { ExtensionApiHandlerContext } from '@src/utils/extension'

export type QueryKeyT = [string, ApiVersion, any]

export type UseQueryOptions<T, E extends Error = AxiosError> = UseQueryOptionsBase<
  T,
  E,
  T,
  QueryKeyT
>
export type UseInfiniteQueryOptions<
  T,
  E extends Error = AxiosError,
> = UseInfiniteQueryOptionsBase<T, E, T, T, QueryKeyT>

export const fetcher = <T>(
  { queryKey, signal }: QueryFunctionContext<QueryKeyT>,
  withoutHandling?: boolean,
  apiHandler?: ApiHandlerInterface,
  isExternal?: boolean,
): Promise<T> => {
  const [url, version, config] = queryKey

  let apiFunc = withoutHandling ? apiWithoutHandling : api

  if (apiHandler) {
    apiFunc = apiHandler
  }

  return apiFunc
    .get<T>(url, signal ? { ...config, signal } : config, version, isExternal)
    .then(res => res.data)
}

const withPageParam =
  <T>(fetcherFunc: typeof fetcher) =>
  (...args: Parameters<typeof fetcher>) => {
    const [context, ...restArgs] = [...args]
    const { pageParam, queryKey } = context
    const [, , config] = queryKey

    config.params.page = pageParam

    return fetcherFunc<T>(context, ...restArgs)
  }

interface UseBaseFetchProps {
  version?: ApiVersion
  params?: any
  withoutHandling?: boolean
  isExternal?: boolean
  useRequestCancelling?: boolean
}

export interface UseFetchProps<T, E extends Error = Error> extends UseBaseFetchProps {
  url: string | null
  queryOptions?: UseQueryOptions<T, E>
}

export interface UseInfiniteFetchProps<T, E extends Error = Error>
  extends UseBaseFetchProps {
  url: string
  initialPage?: number
  queryOptions?: UseInfiniteQueryOptions<T, E>
}

const isProps = <T, E extends Error>(
  input: UseFetchProps<T, E> | string | null,
): input is UseFetchProps<T, E> => {
  return input !== null && typeof input !== 'string'
}

/** @deprecated use `useFetchV2` */
export const useFetch = <T, E extends Error = AxiosError>(
  ...args: [
    url: string | null | UseFetchProps<T, E>,
    version?: ApiVersion,
    params?: any,
    withoutHandling?: boolean,
    queryOptions?: UseQueryOptions<T, E>,
    isExternal?: boolean,
    useRequestCancelling?: boolean,
  ]
): UseFetchResult<T, E> => {
  const {
    params,
    url,
    version,
    queryOptions,
    withoutHandling,
    isExternal,
    useRequestCancelling,
  }: UseFetchProps<T, E> = isProps<T, E>(args[0])
    ? args[0]
    : {
        url: args[0],
        version: args[1],
        params: args[2],
        withoutHandling: args[3],
        queryOptions: args[4],
        isExternal: args[5],
        useRequestCancelling: args[6],
      }
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const context = useQuery<T, E, T, QueryKeyT>(
    [url!, version || 'v1', params],
    ({ queryKey, meta, signal }) =>
      fetcher(
        { queryKey, meta, signal: useRequestCancelling ? signal : undefined },
        withoutHandling,
        apiHandler,
        isExternal,
      ),
    {
      enabled: !!url,
      ...queryOptions,
    },
  )

  return { ...context, refetch: url ? context.refetch : () => Promise.resolve(context) }
}

export const useFetchV2 = <T, E extends Error = AxiosError>(
  args: Omit<UseFetchProps<T, E>, 'withoutHandling'>,
): UseFetchResult<T, E> => {
  return useFetch({ ...args, withoutHandling: true })
}

export const useInfiniteFetch = <T, E extends Error = AxiosError>({
  params,
  url,
  version,
  queryOptions,
  withoutHandling,
  isExternal,
  useRequestCancelling,
  initialPage = 1,
}: UseInfiniteFetchProps<T, E>): UseInfiniteFetchResult<T, E> => {
  const apiHandler = useContext(ExtensionApiHandlerContext)

  return useInfiniteQuery<T, E, T, QueryKeyT>(
    [url, version || 'v1', { ...params, page: initialPage }],
    ({ queryKey, pageParam = initialPage, meta, signal }) =>
      withPageParam<T>(fetcher)(
        {
          queryKey,
          meta,
          pageParam,
          signal: useRequestCancelling ? signal : undefined,
        },
        withoutHandling,
        apiHandler,
        isExternal,
      ),
    queryOptions,
  )
}

/** @deprecated use `useDeleteV2` */
export const useDelete = <T>(
  url: string,
  version: ApiVersion = 'v1',
  params?: any,
  updater?: (oldData: T, id?: string | number) => T,
  withoutHandling?: boolean,
) => {
  const queryClient = useQueryClient()
  const apiHandler = withoutHandling ? apiWithoutHandling : api

  return useMutation<AxiosResponse, AxiosError, string | number | undefined>(
    id => apiHandler.delete(id ? `${url}/${id}` : url, params, version),
    {
      onSuccess: (response, id) => {
        if (response && updater) {
          queryClient.setQueryData<T>([url!, version, params], oldData =>
            updater(oldData!, id),
          )
        }
      },
    },
  )
}

export const useDeleteV2 = <T>(args: {
  url: string
  version?: ApiVersion
  params?: any
  updater?: (oldData: T, id?: string | number) => T
}) => {
  return useDelete(args.url, args.version, args.params, args.updater, true)
}

type UsePostProps<T, S = T> = {
  url: string
  version?: ApiVersion
  params?: any
  updater?: (oldData: T, newData: S) => T
  withoutHandling?: boolean
  handleError?: (err: AxiosError) => void
  handleSuccess?: (data: AxiosResponse<S>) => void
}

const isPostProps = <T, S = T>(
  input: UsePostProps<T, S> | string | null,
): input is UsePostProps<T, S> => {
  return input !== null && typeof input !== 'string'
}

/** @deprecated use `usePostV2` */
export const usePost = <T, S = T, D = S>(
  ...args: [
    url: string | UsePostProps<T, S>,
    version?: ApiVersion,
    params?: any,
    updater?: (oldData: T, newData: S) => T,
    withoutHandling?: boolean,
    handleError?: (err: AxiosError) => void,
    handleSuccess?: (data: AxiosResponse<S>) => void,
  ]
) => {
  const {
    url,
    version = 'v1',
    params,
    updater,
    withoutHandling,
    handleError,
    handleSuccess,
  } = isPostProps<T, S>(args[0])
    ? args[0]
    : {
        url: args[0],
        version: args[1],
        params: args[2],
        updater: args[3],
        withoutHandling: args[4],
        handleError: args[5],
        handleSuccess: args[6],
      }
  const queryClient = useQueryClient()
  const apiHandler = useContext(ExtensionApiHandlerContext)
  let apiFunc = withoutHandling ? apiWithoutHandling : api
  if (apiHandler) {
    apiFunc = apiHandler
  }

  return useMutation<AxiosResponse<S>, AxiosError, D>(
    data => apiFunc.post<S>(url, data, params, version),
    {
      onSuccess: response => {
        if (response.data && updater) {
          queryClient.setQueryData<T>([url!, version, params], oldData =>
            updater(oldData!, response.data),
          )
        }

        if (handleSuccess && typeof handleSuccess === 'function') {
          handleSuccess(response)
        }
      },
      onError: handleError ? (err: AxiosError) => handleError(err) : undefined,
    },
  )
}

export const usePostV2 = <T, S = T, D = S>(
  args: Omit<UsePostProps<T, S>, 'withoutHandling'>,
) => {
  return usePost<T, S, D>({ ...args, withoutHandling: true })
}

type UseUpdateProps<T> = {
  url: string
  version?: ApiVersion
  params?: any
  usePut?: boolean
  updater?: (oldData: T, newData: T, id: string | number | undefined) => T
  withoutHandling?: boolean
  useId?: boolean
}

const isUpdateProps = <T>(
  input: UseUpdateProps<T> | string | null,
): input is UseUpdateProps<T> => {
  return input !== null && typeof input !== 'string'
}

/** @deprecated use `useUpdateV2` */
export const useUpdate = <T, S>(
  ...args: [
    url: string | UseUpdateProps<T>,
    version?: ApiVersion,
    params?: any,
    usePut?: boolean,
    updater?: (oldData: T, newData: T, id: string | number | undefined) => T,
    withoutHandling?: boolean,
    useId?: boolean,
  ]
) => {
  const {
    url,
    version = 'v1',
    params,
    usePut,
    updater,
    withoutHandling,
    useId = true,
  } = isUpdateProps<T>(args[0])
    ? args[0]
    : {
        url: args[0],
        version: args[1],
        params: args[2],
        usePut: args[3],
        updater: args[4],
        withoutHandling: args[5],
        useId: args[6],
      }

  const queryClient = useQueryClient()
  const apiHandler = useContext(ExtensionApiHandlerContext)
  let apiFunc = withoutHandling ? apiWithoutHandling : api
  if (apiHandler) {
    apiFunc = apiHandler
  }

  return useMutation<AxiosResponse<T>, AxiosError, [string | number | undefined, S]>(
    ([id, data]) =>
      usePut
        ? apiFunc.put<T>(id ? `${url}/${id}` : url, data, params, version)
        : apiFunc.patch<T>(id ? `${url}/${id}` : url, data, params, version),
    {
      onSuccess: (response, [id]) => {
        if (response.data && updater) {
          queryClient.setQueryData<T>(
            [id && useId ? `${url}/${id}` : url, version, params],
            oldData => updater(oldData!, response.data, id),
          )
        }
      },
    },
  )
}

export const useUpdateV2 = <T, S>(args: Omit<UseUpdateProps<T>, 'withoutHandling'>) => {
  return useUpdate<T, S>({ ...args, withoutHandling: true })
}
