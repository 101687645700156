import React from 'react'
import { Action, Box, HStack, Icon, Token } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Table from '@components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import { GrowthPlanRelationInterface } from '@src/interfaces/growthPlans'
import { GrowthPlansRelationsQueryKeys } from '@src/api/growthPlans'
import { navigateTo } from '@src/actions/RouterActions'
import { getDefaultStatusVariant } from '@components/TableV2/Cells/StatusCell'
import { getEmployeePerformanceGraph } from '@src/api/employees'
import { TalentPerformanceGradeColumn } from '@components/TalentPerformanceGradeColumn/TalentPerformanceGradeColumn'
import EmployeePerformanceChart from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { Statuses } from '@src/interfaces'

const TooltipContainer = ({ children }: { children: React.ReactNode }) => (
  <Box p="s-12" minWidth={140}>
    {children}
  </Box>
)

export const employeeRelationColumn: ColumnInterface<GrowthPlanRelationInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: GrowthPlansRelationsQueryKeys.FullName,
  filterKey: GrowthPlansRelationsQueryKeys.Id,
  dynamicHyperlinks: ({ id }) => pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
  insert: ({ data: employeeRelation }) => (
    <Table.EmployeeCell employee={employeeRelation} />
  ),
  selectorsKey: selectorKeys.all_employees,
  title: 'Employee',
}

export const gradeColumn: ColumnInterface<GrowthPlanRelationInterface> = {
  type: CellTypes.insert,
  idPoint: 'latest_review_score_label.id',
  dataPoint: 'latest_review_score_label.id',
  sortKey: GrowthPlansRelationsQueryKeys.LatestReviewScoreLabel,
  filterKey: GrowthPlansRelationsQueryKeys.LatestReviewScoreLabel,
  selectorsKey: selectorKeys.performance_grades,
  title: 'Grade',
  masked: true,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
  colors: ({ current_growth_plan }) =>
    !current_growth_plan ? Token.color.greyTone50 : 'inherit',
  insert: ({ data: employeeRelation }) =>
    employeeRelation.latest_review_score_label ? (
      <EmployeePerformanceChart
        id={employeeRelation.id}
        // TODO: update to correct endpoint call when it's implemented https://revolut.atlassian.net/browse/REVC-7514
        // the data should be the same api/v1/employees/{employee_id}/performanceHistory returns for department owners
        fetchKpi={getEmployeePerformanceGraph}
        vertical="right"
      >
        <HStack space="s-4">
          <TalentPerformanceGradeColumn
            id={employeeRelation.id}
            name="latest_grade"
            grade={employeeRelation.latest_review_score_label}
          />
          <Icon name="BarChart" size={16} color={Token.color.greyTone20} />
        </HStack>
      </EmployeePerformanceChart>
    ) : (
      'N/A'
    ),
}

export const targetRoleColumn: ColumnInterface<GrowthPlanRelationInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_growth_plan.target_specialisation.id',
  dataPoint: 'current_growth_plan.target_specialisation.id',
  sortKey: GrowthPlansRelationsQueryKeys.TargetSpecialisationId,
  filterKey: GrowthPlansRelationsQueryKeys.TargetSpecialisationId,
  selectorsKey: selectorKeys.specialisations,
  title: 'Target Role',
  colors: ({ current_growth_plan }) =>
    !current_growth_plan ? Token.color.greyTone50 : 'inherit',
  insert: ({ data: { current_growth_plan } }) =>
    current_growth_plan?.growth_plan.target_specialisation.name || 'N/A',
}

export const targetSeniorityColumn: ColumnInterface<GrowthPlanRelationInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_growth_plan.target_seniority.id',
  dataPoint: 'current_growth_plan.target_seniority.id',
  sortKey: GrowthPlansRelationsQueryKeys.TargetSeniorityId,
  filterKey: GrowthPlansRelationsQueryKeys.TargetSpecialisationId,
  selectorsKey: selectorKeys.seniority,
  title: 'Target Seniority',
  colors: ({ current_growth_plan }) =>
    !current_growth_plan ? Token.color.greyTone50 : 'inherit',
  insert: ({ data: { current_growth_plan } }) =>
    current_growth_plan ? (
      <Table.SeniorityCell
        seniority={current_growth_plan.growth_plan.target_seniority.name}
      />
    ) : (
      'N/A'
    ),
}

export const ownerColumn: ColumnInterface<GrowthPlanRelationInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_growth_plan.owner.id',
  dataPoint: 'current_growth_plan.owner.id',
  sortKey: GrowthPlansRelationsQueryKeys.OwnerId,
  filterKey: GrowthPlansRelationsQueryKeys.OwnerId,
  dynamicHyperlinks: ({ current_growth_plan }) =>
    current_growth_plan?.growth_plan.owner
      ? pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
          id: current_growth_plan.growth_plan.owner.id,
        })
      : null,
  insert: ({ data: { current_growth_plan } }) =>
    current_growth_plan?.growth_plan.owner ? (
      <Table.EmployeeCell employee={current_growth_plan.growth_plan.owner} compact />
    ) : (
      '-'
    ),
  selectorsKey: selectorKeys.all_employees,
  title: 'Owner',
}

export const statusColumn: ColumnInterface<GrowthPlanRelationInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_growth_plan.status',
  dataPoint: 'current_growth_plan.status',
  sortKey: GrowthPlansRelationsQueryKeys.Status,
  filterKey: GrowthPlansRelationsQueryKeys.Status,
  selectorsKey: selectorKeys.growth_plan_statuses,
  title: 'Status',
  insert: ({ data: { current_growth_plan, id } }) =>
    current_growth_plan ? (
      <Table.StatusCell
        status={current_growth_plan.growth_plan.status || Statuses.draft}
        variant={getDefaultStatusVariant(
          current_growth_plan.growth_plan.status || Statuses.draft,
        )}
      />
    ) : (
      <Action
        onClick={() => {
          navigateTo(pathToUrl(ROUTES.FORMS.GROWTH_PLAN.CREATE, { employeeId: id }))
        }}
      >
        Set up
      </Action>
    ),
}
